import { graphql, Link } from "gatsby"
import React from "react"

import Layout from "../../components/layouts"
import Banner from "../../components/resources/blog/Banner"
import SEO from "../../components/common/SEO"

// Query for the Course content in Prismic
export const query = graphql`
  query TermsQuery {
    seo: site {
      siteMetadata {
        url
        twitterUsername
      }
    }
  }
`

const Terms = props => {
  // Banner content
  const bannerContent = {
    categories: "",
    title: "Terms & Conditions",
    //   author: content.author
    //     ? content.author.first_name + " " + content.author.last_name
    //     : "",
    //   date: content.date_created,
  }

  const seo = {
    title: "Workhub | Terms & Conditions",
    description: "The terms to use workhubs website is to agree to not damage workhub and accepting the responsibility to verify all information with caution.",
    //   image: (imageUrl) ? imageUrl : null,
    // pathname,
    // article
  }

  return (
    <Layout>
      <SEO {...seo} />
      <Banner bannerContent={bannerContent} />
      <section className="blog_area_two sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 blog_single_info">
              <div className="blog_list_item blog_list_item_two">
                <div className="blog_content">
                  <h1>
                    INTRODUCTION
                  </h1>
                  <p>
                    This policy pertains to the TERMS OF USE for Workhub&rsquo;s
                    websites.&nbsp;AS WITH MOST OTHER WEBSITES, WHEN YOU ARE
                    USING OUR WEBSITE YOU ARE AGREEING TO THE TERMS OF USE.
                    Generally, YOU ARE AGREEING TO NOT DAMAGE WORKHUB THROUGH
                    USING OR VIEWING OUR WEBSITE AND THAT YOU ACCEPT
                    RESPONSIBILITY TO VERIFY ALL INFORMATION AND USE all
                    information WITH CAUTION, ENSURING THAT IT MEETS YOUR NEEDS.
                  </p>
                  <p>
                    IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, THEN YOU
                    MAY NOT USE ANY OF THE WORKHUB WEBSITE OR MAKE PURCHASES.
                  </p>
                  <h1>
                    TERMS OF USE
                  </h1>
                  <p>
                    These TERMS OF USE are a binding agreement between you and
                    Workhub Software Corp. and Workhub Software Inc.,
                    (collectively,<strong> &ldquo;WORKHUB&rdquo;</strong>)
                    regarding purchases and your access to and use of the
                    WORKHUB, SAFETYSYNC and WORKERID websites and services
                    (collectively the&nbsp;&ldquo;<strong>Website</strong>
                    &rdquo;). Each time you use the Website or software, you
                    signify your unconditional acceptance and agreement, without
                    limitation or qualification, to the most current version of
                    these Terms and Conditions. If you do not unconditionally
                    accept and agree to these Terms and Conditions, then you may
                    not use the Website.
                  </p>
                  <p>
                    To access our software you are required to set up or
                    activate (if an account was set up for you by your
                    administrator) an account (an &ldquo;
                    <strong>Account</strong>&rdquo;). If you create an Account,
                    you must login to purchase services and license our
                    software. Logging into an Account requires a unique user
                    name and password (collectively, a &ldquo;
                    <strong>User ID</strong>&rdquo;).
                  </p>
                  <p>
                    ANY VISIT TO THE WEBSITE, CREATION OF AN ACCOUNT, ANY LOGIN
                    TO YOUR ACCOUNT, ANY PURCHASE AS AN ACCOUNT HOLDER,
                    CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO THE THESE
                    TERMS OF USE AS MOST RECENTLY UPDATED.
                  </p>
                  <p>
                    IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MAY
                    NOT USE THE WEBSITE, OPEN AN ACCOUNT, LOGIN TO YOUR ACCOUNT,
                    LICENSE OR USE OUR SOFTWARE.
                  </p>
                  <p>
                    IF YOU HAVE ANY QUESTIONS OR CONCERNS ABOUT THESE TERMS OF
                    USE PLEASE CONTACT info@Workhub.com
                  </p>
                  <p>
                    <strong>Updates to the Terms and Conditions:&nbsp; </strong>
                    WORKHUB may in its discretion revise, modify, change or
                    otherwise update these TERMS OF USE (and any other documents
                    referenced in these Terms and Conditions), including the
                    <Link to="/privacy" style={{margin: '0 5px'}}>Privacy Policy</Link>
                    at any time and from time to time, with at least sixty (60)
                    days&rsquo; notice, by posting the changed TERMS OF USE on
                    the Website homepage The changed TERMS OF USE are effective
                    sixty (60) days after the notice of change is posted on the
                    Website, unless the changed TERMS OF USE expressly state
                    otherwise. It is your responsibility to check the
                    &ldquo;Last Updated&rdquo; date at the top of these TERMS OF
                    USE and review any changes since the previous version. Your
                    continued use of the Website, including your Account, after
                    any modification of these TERMS OF USE constitutes your
                    acceptance of and agreement with the TERMS OF USE as
                    updated. A notification of any update to the TERMS OF USE
                    will be posted on the Website home page. Where required by
                    applicable law, you will be notified by email or other
                    method through your Account at contact information you have
                    supplied in setting up an Account, or at contact information
                    you have supplied for any newsletter, opt-in communication,
                    or otherwise, if and when these TERMS OF USE are updated.
                  </p>
                  <p>
                    <strong>
                      Eligibility based on Residence and Age:&nbsp;{" "}
                    </strong>
                    As required by applicable law the Website and services are
                    intended for use by individuals who are the age of majority
                    in their jurisdiction of residence. You must be the age of
                    majority in your jurisdiction of residence to make purchases
                    or redemptions on the Website.
                  </p>
                  <p>
                    WORKHUB MAKES NO REPRESENTATION OR WARRANTY THAT THE SITE,
                    THE CONTENT OR THE SERVICES ARE APPROPRIATE OR AVAILABLE FOR
                    ACCESS OR OTHER USE IN ALL LOCATIONS AND JURISDICTIONS. IF
                    YOU ARE ACCESSING OR USING THE SITE FROM OUTSIDE OF CANADA,
                    INCLUDING LOGGING INTO AN ACCOUNT, ORDERING SERVICES,
                    REVIEWING CONTENT (INCLUDING ANY PUBLICLY-AVAILABLE OR
                    LOGIN-ONLY CONTENT RELATED TO THE SERVICES), OR OTHERWISE
                    USING THE SITE OR SERVICES, IT IS SOLELY YOUR RESPONSIBILITY
                    TO ENSURE THAT ANY SUCH ACCESS OR USE COMPLIES WITH
                    APPLICABLE LAWS OF ANY KIND WHATSOEVER IN THE JURISDICTION
                    FROM WHICH YOU ARE ACCESSING OR USING THE SITE.
                  </p>
                  <p>
                    <strong>Account: </strong>You need to have an Account to
                    license our software and access our services. In the course
                    of being granted access to your Account, you will be
                    required to create a User ID. Your User ID identifies and
                    associates you with your Account and is required for you to
                    login to your account. With your User ID, you may log in to
                    your account and license software or sign up for services
                    and make payments. You agree to: (<strong>a</strong>)
                    immediately notify WORKHUB by email at support@workhub.com
                    of any unauthorized use of your Account or any other breach
                    of security, or any suspicion of such authorized use of your
                    Account, and to identify your user name in the email, and (
                    <strong>b</strong>) ensure that you log out from your
                    Account at the end of each session. YOU AGREE THAT YOU ARE
                    FULLY RESPONSIBLE FOR ALL ACTIVITIES PERFORMED USING YOUR
                    ACCOUNT AND THAT WORKHUB HAS NO LIABILITY WHATSOEVER FOR ANY
                    LOSS, LIABILITY, INJURY OR DAMAGE ARISING FROM YOUR FAILURE
                    TO COMPLY WITH YOUR OBLIGATIONS WHILE ACCESSING THE SERVICES
                    OR OTHERWISE ACCESSING YOUR ACCOUNT.
                  </p>
                  <p>
                    <strong>User ID:&nbsp; </strong>Your unique user name and
                    password are, collectively, your User ID. Your User ID is
                    the key to your Account. You are responsible for maintaining
                    the confidentiality of your Account, including your User ID.
                    Use a password that is different from any passwords you have
                    on social media or other public websites. Use unique
                    numbers, letters and special characters in your password. Do
                    not disclose your User ID to anyone or share your User ID
                    with anyone. If you lose control of your User ID, you may
                    lose control over your personal information and may be
                    subject to legally binding actions taken on your behalf and
                    associated liability. Therefore, if your User ID has been
                    compromised for any reason, you should immediately notify us
                    at support@workhub.com and change your password.
                  </p>
                  <p>
                    <strong>Prohibited Use of the Services: </strong>You agree
                    not to make any unlawful use of the services. In particular,
                    but without limiting the generality of the foregoing, you
                    acknowledge that the services are provided solely for your
                    personal use.
                  </p>
                  <p>
                    <strong>Account Status:&nbsp; </strong>You agree that we may
                    refuse, cancel or terminate any Account, or refuse to sell
                    to you at any time and without notice if, at our discretion
                    we determine that you have failed to comply with these Terms
                    and Conditions, or you are otherwise ineligible use this
                    Website for any reason. YOU AGREE THAT WORKHUB HAS NO
                    LIABILITY WHATSOEVER FOR ANY LOSS, LIABILITY, INJURY, OR
                    DAMAGE OF ANY KIND HOWSOEVER ARISING FROM ANY REFUSED,
                    SUSPENDED, CANCELED OR TERMINATED ACCOUNT.
                  </p>
                  <p>
                    <strong>Sale of Services:</strong>&nbsp; WORKHUB reserves
                    the right, in its discretion, to cancel or refuse orders for
                    failure to comply with applicable law, including if we
                    believe that you have made a false or fraudulent order that
                    is or appears to be inconsistent in any way with applicable
                    law, and to inform the relevant authorities in accordance
                    with applicable law. All sales are subject to availability,
                    which may in some circumstances change during checkout,
                    payment and confirmation processes associated with a given
                    order. WORKHUB may, in its discretion, limit or cancel the
                    quantities offered on the Website or limit licenses of
                    software services to any person, household, geographic
                    region or jurisdiction.
                  </p>
                  <p>
                    <strong>Prices: </strong>Unless otherwise stated, all prices
                    are in United States Dollars. Prices for all services are
                    indicated on the Site and are subject to change without
                    notice. Where a price is inconsistent as between publicly
                    accessible portions of the Website and portions of the
                    Website available only by logging in to an Account, the
                    price as indicated on the portion of the Website available
                    only by logging in to an Account is the correct price.
                  </p>
                  <p>
                    <strong>
                      Prohibited Use of the Website or Software:&nbsp;{" "}
                    </strong>
                    You may not use the Website or Software or any content for
                    any unlawful purpose or any unsafe purpose.&nbsp; You may
                    not use the Website or Service for commercial enterprise
                    without written consent from Workhub. Without limiting the
                    generality of the foregoing, while accessing or using the
                    Website, including reviewing content or using the services,
                    you agree that you will not:
                  </p>
                  <ol style={{ marginLeft: "40px" }}>
                    <li>
                      violate any laws, any third party rights or our policies;
                    </li>
                    <li>
                      access or use the Website or services if you are not able
                      to form legally binding contracts, are under the age of
                      majority in your province or territory of residence, or
                      are temporarily or indefinitely suspended from accessing
                      or using the Website or services;
                    </li>
                    <li>
                      access or use the Website in any manner which could
                      damage, disable, overburden or impair the Website;
                    </li>
                    <li>
                      transfer or make available your Account, User ID to
                      another party;
                    </li>
                    <li>
                      interfere with the security of, or otherwise abuse, the
                      Website, the services, or any system resources, accounts,
                      servers or networks connected to or accessible through the
                      Website or affiliated or linked sites;
                    </li>
                    <li>
                      distribute viruses or any other technologies that may harm
                      or prejudice the interests, rights, or property of WORKHUB
                      or of any other person accessing or using the Website;
                    </li>
                    <li>
                      use any robot, spider or other automatic device, to index,
                      crawl, catalogue, mirror, frame, scrape, cache or
                      otherwise monitor or copy any web page of the Website or
                      any content to collect or mine data from the Website;
                    </li>
                    <li>
                      use any manual process to monitor or copy any web page of
                      the Website or any content other than as provided for in
                      these Terms and Conditions;
                    </li>
                    <li>
                      obtain unauthorized access to the Website or Software or
                      portions of the Website or Software that are restricted
                      from general access;
                    </li>
                    <li>
                      copy, modify or distribute rights or content from the
                      Website, including content subject to copyright and common
                      law or registered trademark rights owned by or licensed
                      to, without limitation, WORKHUB, and whether or not for
                      consideration or for commercial purposes;
                    </li>
                    <li>
                      harvest or otherwise collect information about any other
                      user of the Website or Software, including email
                      addresses, without their consent and compliance with all
                      law; or
                    </li>
                    <li>attempt to complete any of the above actions.</li>
                  </ol>
                  <p>
                    Without limiting other recourse, including but not limited
                    to any remedy available under applicable law, we may limit,
                    delay, suspend or terminate any access to the Website,
                    content, Account, access to services or services, linked
                    Websites, or take technical and legal steps to keep users
                    off the Website if we believe, in our discretion, that the
                    user is creating problems or possible legal liabilities,
                    infringing the intellectual property rights of WORKHUB&nbsp;
                    or third parties, or otherwise acting inconsistently with
                    the letter or spirit of these TERMS OF USE or our policies,
                    or otherwise to the determinant of WORKHUB&nbsp; Group (as
                    defined below), or any other person. Without limiting the
                    generality of the foregoing, we may, in appropriate
                    circumstances and at our discretion, suspend or terminate
                    access, or modify or discontinue the Website, Software, or
                    services, at our discretion.
                  </p>
                  <p>
                    <strong>No Spam, Spyware or Spoofing:&nbsp; </strong>WORKHUB
                    takes compliance with laws pertaining to commercial
                    electronic messages, including but not limited to Canadian
                    Anti-Spam Legislation (&ldquo;<strong>CASL</strong>&rdquo;),
                    very seriously. We will not send you commercial electronic
                    messages without consent as set forth in CASL. For example,
                    you may choose to receive a newsletter or other commercial
                    electronic messages from WORKHUB on an opt-in basis, and
                    having done so may opt out at any time. You may not use our
                    communication tools or the Website more broadly to
                    distribute spam, distribute or leverage spyware, or
                    otherwise send content that would violate these TERMS OF USE
                    or any applicable law.
                  </p>
                  <p>
                    <strong>Accuracy of Website Content:&nbsp; </strong>All
                    content is provided on an &ldquo;as is&rdquo; basis for
                    informational purposes only and is subject to change without
                    notice. While the Website and the content are regularly
                    updated, the content may be inaccurate, out-of-date, or
                    otherwise incorrect. WORKHUB assumes no liability or
                    responsibility for any such errors, omissions or
                    inaccuracies and makes no representations about the
                    accuracy, reliability, completeness, or timeliness of the
                    content. Without limiting the generality of the foregoing,
                    and subject to the limitations on liability disclaimers
                    included in these Terms and Conditions, WORKHUB does
                    not:&nbsp; (<strong>a</strong>) make any representation or
                    warranty regarding the accuracy, completeness, or usefulness
                    of the content, or (<strong>b</strong>) adopt, endorse, or
                    accept responsibility for the accuracy or reliability of any
                    opinion, advice, or statement made by WORKHUB or by any
                    third party, which appears on the Website. Subject to the
                    foregoing, WORKHUB works to keep the Website and services
                    updated and working properly. Please report any perceived
                    problems, perceived offensive or inappropriate content, and
                    any perceived policy violations to us by email at
                    legal@workhub.com.
                  </p>
                  <p>
                    <strong>Content and Intellectual Property Rights: </strong>
                    The Website and all content, including these TERMS OF USE
                    are &copy; 2019 Workhub Software Corp. its suppliers or
                    affiliates. All rights reserved. The layout for the Website
                    and all content and software is owned by or licensed to
                    WORKHUB&nbsp; and is protected under applicable law
                    including the <em>Copyright Act</em> (Canada), common law
                    rights, and by other copyright laws in other jurisdictions.
                    No content may be reproduced, adapted, uploaded to a third
                    party, linked to, framed, performed in public, distributed
                    or transmitted in any form by any process without the
                    specific written consent of WORKHUB, other than as expressly
                    provided for in these Terms and Conditions. Nothing on the
                    Website, your use of the services or Software shall be
                    construed as conferring any transfer of rights to you of any
                    intellectual property or other proprietary or exclusionary
                    rights of WORKHUB&nbsp; or any third party, whether by
                    estoppel, by implication or otherwise, and whether defined
                    by statute, including any ownership or other rights related
                    to pending or issued applications for patents, trademark
                    registrations, registered plant breeder&rsquo;s rights,
                    copyright registrations, or by common law, including any
                    rights in copyright, goodwill, trademark, branding, trade
                    secret, or confidential information (collectively, &ldquo;
                    <strong>Intellectual Property Rights</strong>&rdquo;).
                  </p>
                  <p>
                    <strong>Trademarks:&nbsp; </strong>Without limiting the
                    generality of the foregoing, the product names, company
                    names and logos of WORKHUB, any affiliate or any third party
                    used on the Website may be trademarks, including registered
                    trademarks of WORKHUB. Such trademarks, product names,
                    company names or logos may not be copied, imitated or used,
                    in whole or in part, without the prior written consent of
                    WORKHUB or any owners or other relevant parties as
                    applicable relating to the trademarks, product names,
                    company names or logos.
                  </p>
                  <p>
                    <strong>Reservation of Rights:</strong>&nbsp; Without
                    limiting the generality of the foregoing, the services, or
                    other of WORKHUB&rsquo;s methods and processes may be
                    subject to rights, including Intellectual Property Rights,
                    of WORKHUB or a third party. WORKHUB and any applicable
                    third parties reserve all such rights.
                  </p>
                  <p>
                    <strong>Privacy:&nbsp; </strong>By using the Website,
                    including by reviewing the content, using the services,
                    logging into an Account, subscribing to a newsletter or
                    other opt-in communication, or submitting an order for
                    services, you consent to the collection, use, disclosure and
                    retention of your personal information by or on behalf of
                    WORKHUB&nbsp; as explained in the WORKHUB <Link to="/privacy" style={{margin: '0 5px'}}>Privacy Policy</Link>, as
                    revised from time to time, and as otherwise permitted or
                    required by applicable law.
                  </p>
                  <p>
                    <strong>GDRP Compliance: </strong>If you are a resident of
                    the European Union and have questions about our compliance
                    with the General Data Protection Regulation, or wish to
                    access your personal information, please contact our Privacy
                    Officer &ndash; legal@Workhub.com
                  </p>
                  <p>
                    <strong>Confidentiality:&nbsp; </strong>Electronic
                    communications, including over the internet and through
                    email, are not secure means of communication and the
                    privacy, integrity or authenticity of any communication over
                    the Internet with you shall not expose WORKHUB to any
                    liability for damages you may suffer as a result of
                    communicating with WORKHUB by electronic communications or
                    if WORKHUB communicates such information to you at your
                    request.
                  </p>
                  <p>
                    <strong>Outbound Links</strong>:&nbsp; The Website may
                    contain links to third-party web sites and resources. These
                    third-party links websites are provided solely as a
                    convenience to you and as a service, and not as an
                    endorsement by WORKHUB of the third-party websites or
                    resources, or of any product or services offered or endorsed
                    by such third parties. WORKHUB makes no representations or
                    warranties regarding the availability, correctness,
                    accuracy, performance or quality of any linked website or
                    any content, software, service or application found at any
                    linked website.
                  </p>
                  <p>
                    <strong>Inbound Links:&nbsp; </strong>You agree not to link
                    to the Website in association with any false, inaccurate,
                    misleading, misappropriated, defamatory or libelous content,
                    in association with any spam, unsolicited or bulk electronic
                    messages, including commercial electronic messages. Subject
                    to these Terms and Conditions, WORKHUB generally agrees with
                    linking to the Website through a link, including a plain
                    text link or logo link, without an additional written
                    agreement between yourself and WORKHUB. Notwithstanding the
                    foregoing, WORKHUB reserves the right to require that you
                    remove any link from a website owned, operated, posted on or
                    otherwise used by you to publish a reference to the Website
                    through a link, at WORKHUB &rsquo; discretion, including for
                    posting any link in association with, displayed with, or in
                    any way connected with the Website, WORKHUB , or
                    WORKHUB&nbsp; Group (as defined below in these Terms and
                    Conditions).
                  </p>
                  <p>
                    <strong>No Agency:&nbsp; </strong>No agency, partnership,
                    joint venture, employee-employer or franchiser-franchisee
                    relationship is intended or created by these Terms and
                    Conditions.
                  </p>
                  <p>
                    <strong>WORKHUB&nbsp; GROUP:&nbsp; </strong>THE FOLLOWING
                    DISCLAIMERS, LIMITATION OF LIABILITY AND INDEMNIFICATION
                    PROVISIONS REFERENCE AND APPLY TO WORKHUB , INCLUDING ALL
                    AFFILIATES OF OR ENTITIES RELATED TO WORKHUB&nbsp; SOFTWARE
                    CORP. AND ANY OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF
                    WORKHUB&nbsp; SOFTWARE CORP., OR OF ANY AFFILIATE OR RELATED
                    ENTITY, ANY OF THE FOREGOING ALONE OR IN ANY COMBINATION
                    (COLLECTIVELY, THE &ldquo;
                    <strong>WORKHUB&nbsp; GROUP</strong>&rdquo;).
                  </p>
                  <p>
                    <strong>DISCLAIMER:&nbsp; </strong>TO THE MAXIMUM EXTENT
                    PERMITTED BY APPLICABLE LAW THE WEBSITE, THE CONTENT, THE
                    SERVICES, AND THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo;,
                    WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND BY
                    WORKHUB GROUP, EITHER EXPRESS OR IMPLIED, OTHER THAN LEGAL
                    WARRANTIES OF PUBLIC ORDER. WITHOUT LIMITING THE FOREGOING,
                    WORKHUB GROUP EXPRESSLY DISCLAIMS ANY WARRANTIES OR
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY
                    WARRANTIES ARISING OUT OF THE COURSE OF DEALING OR USAGE OF
                    TRADE OTHER THAN LEGAL WARRANTIES OF PUBLIC ORDER. WORKHUB
                    GROUP MAKES NO WARRANTY THAT THE WEBSITE, THE CONTENT, THE
                    SOFTWARE, OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE
                    AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS
                    OTHER THAN LEGAL WARRANTIES OF PUBLIC ORDER. WORKHUB GROUP
                    MAKES NO WARRANTY REGARDING THE QUALITY OF THE WEBSITE, THE
                    CONTENT, THE SOFTWARE, OR THE SERVICES, OR THE ACCURACY,
                    TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY
                    CONTENT ON THE WEBSITE OTHER THAN LEGAL WARRANTIES OF PUBLIC
                    ORDER.
                  </p>
                  <p>
                    <strong>
                      DISCLAIMER OF WORKHUB&nbsp; GROUP COMMUNICATIONS:&nbsp;{" "}
                    </strong>
                    <u>
                      THE FOLLOWING CLAUSE IS NOT APPLICABLE TO CONSUMERS IN
                      QU&Eacute;BEC
                    </u>
                    :&nbsp; NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                    OBTAINED THROUGH THE WEBSITE OR OTHERWISE FROM WORKHUB&nbsp;
                    GROUP, RELATING TO THE SERVICES OR THE SERVICES, CREATES ANY
                    WARRANTY OR CONDITION OTHER THAN AS EXPRESSLY MADE IN THESE
                    TERMS OF USE OR AS REQUIRED BY APPLICABLE LAW.
                  </p>
                  <p>
                    <strong>
                      DISCLAIMER OF THIRD PARTY STATEMENTS:&nbsp;{" "}
                    </strong>
                    YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS
                    AND INTERACTIONS WITH OTHER USERS OF THE WEBSITE, AND WITH
                    OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT BECAUSE
                    of YOUR USE OF THE WEBSITE, THE CONTENT, SOFTWARE, OR THE
                    SERVICES. YOU UNDERSTAND THAT WORKHUB GROUP DOES NOT ATTEMPT
                    TO VERIFY THE STATEMENTS MADE BY OTHER PERSONS ABOUT THE
                    WEBSITE, THE CONTENT, THE SOFTWARE, AND THE SERVICES OR
                    WORKHUB GROUP.
                  </p>
                  <p>
                    <strong>LIMITATION OF LIABILITY:&nbsp; </strong>
                    <u>
                      THE LIMITATIONS OF LIABILITY SET OUT IN ITEMS (G) AND (H)
                      BELOW DO NOT APPLY TO CONSUMERS IN QU&Eacute;BEC
                    </u>
                    : YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE MAXIMUM
                    EXTENT PERMITTED BY APPLICABLE LAW, WORKHUB&nbsp; GROUP IS
                    NOT AND SHALL NOT BE HELD RESPONSIBLE OR LIABLE TO YOU OR
                    ANY PERSON FOR ANY CLAIM OR REMEDY WHATSOEVER INCLUDING ANY
                    CLAIM FOR, OR REMEDY INCLUDING, DAMAGES, EQUITABLE RELIEF,
                    INJUNCTIVE RELIEF, OR OTHER REMEDY, INCLUDING ANY DIRECT,
                    INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
                    DAMAGES, INCLUDING REMEDIES FOR ANY ADVERSE REACTION,
                    PERSONAL INJURY (INCLUDING DEATH), LOSS OF USE, DISCLOSURE
                    OR LOSS OF DATA, LOST PROFITS, BREACH OF CONFIDENCE, LEGAL,
                    FINANCIAL OR OTHER PROFESSIONAL FEES, CONSULTING FEES, OR
                    OTHER INTANGIBLE LOSSES, WHATSOEVER AND HOWSOEVER CAUSED,
                    REGARDLESS OF THE THEORY OF LAW PROVIDING A BASIS FOR THE
                    REMEDY (INCLUDING CONTRACT, TORT, OR STATUTE), FOR ANY LOSS
                    ARISING OUT OF OR IN ANY WAY CONNECTED WITH
                  </p>
                  <p>
                    (<strong>A</strong>) YOUR USE OR APPLICATION OF THE WEBSITE,
                    THE CONTENT, THE SOFTWARE OR THE SERVICES;
                  </p>
                  <p>
                    (<strong>B</strong>) YOUR INABILITY TO USE OR APPLY THE
                    WEBSITE, THE CONTENT, THE SOFTWARE OR THE SERVICES;
                  </p>
                  <p>
                    (<strong>C</strong>) YOUR USE OF OR RELIANCE ON ANY CONTENT
                    OR OTHER INFORMATION DISPLAYED ON, HOSTED ON, CONTAINED ON,
                    OR OTHERWISE ACCESSIBLE THROUGH THE WEBSITE, WHETHER IN
                    CONNECTION WITH THE SERVICES OR OTHER ASPECTS OF THE
                    WEBSITE;
                  </p>
                  <p>
                    (<strong>D</strong>) WITH THE SELECTION, ADOPTION OR
                    IMPLEMENTATION OF ANY PARTICULAR COURSE OF TREATMENT FOR ANY
                    ILLNESS OR CONDITION;
                  </p>
                  <p>
                    (<strong>E</strong>) ANY MISUSE OF THE WEBSITE BY YOU OR
                    OTHER PERSONS, INCLUDING IN CONTRAVENTION OF THESE TERMS AND
                    CONDITIONS;
                  </p>
                  <p>
                    (<strong>F</strong>) ANY MISUSE OF AN ACCOUNT, USER ID
                    INCLUDING BY COMMUNICATION OF A USER ID OR OTHER
                    INFORMATION, INCLUDING REGISTRATION INFORMATION, SUCH MISUSE
                    INCLUDING USE OF A USER ID BY AN INDIVIDUAL OTHER THAN BY AN
                    ACCOUNT HOLDER;
                  </p>
                  <p>
                    (<strong>G</strong>){" "}
                    <u>
                      THIS ITEM (G) DOES NOT APPLY TO CONSUMERS IN QU&Eacute;BEC
                    </u>
                    :&nbsp; YOUR USE OR APPLICATION OF THE SERVICES;
                  </p>
                  <p>
                    (<strong>H</strong>){" "}
                    <u>
                      THIS ITEM (H) DOES NOT APPLY TO CONSUMERS IN QU&Eacute;BEC
                    </u>
                    :&nbsp; YOUR USE OF OR RELIANCE ON ANY CONTENT OR OTHER
                    INFORMATION DISPLAYED ON, HOSTED ON, CONTAINED ON, OR
                    OTHERWISE ACCESSIBLE THROUGH THE WEBSITE, IN CONNECTION WITH
                    THE SERVICES; OR
                  </p>
                  <p>
                    (<strong>I</strong>) ANY OTHER MATTER RELATED TO THE
                    WEBSITE.
                  </p>
                  <p>
                    IF YOU CHOOSE TO USE THE WEBSITE, THE CONTENT, THE SOFTWARE,
                    THE SERVICES, YOU DO SO AT YOUR DISCRETION AND RISK, AND
                    WITHOUT ANY RECOMMENDATION TO DO SO FROM WORKHUB GROUP.
                  </p>
                  <p>
                    THIS LIMITATION OF LIABILITY APPLIES NOTWITHSTANDING, AS
                    APPLICABLE (<strong>A</strong>) ANY POTENTIAL LIABILITY
                    HAVING BEEN REASONABLY FORESEEABLE, (<strong>B</strong>) ANY
                    ERROR OR OMISSION BY WORKHUB GROUP OR IN THE CONTENT WHETHER
                    OR NOT WORKHUB GROUP KNEW OR OUGHT TO HAVE KNOWN OF, OR WERE
                    OTHERWISE RESPONSIBLE FOR, ANY SUCH ERROR OR OMISSION, (
                    <strong>C</strong>) WORKHUB GROUP HAVING BEEN INFORMED OF
                    THE POSSIBILITY OF POTENTIAL LIABILITY, OR (
                    <strong>D</strong>) OTHER REMEDIES NOT BEING AVAILABLE OR
                    NOT ADEQUATELY COMPENSATING YOU OR ANY OTHER PERSON.
                  </p>
                  <p>
                    <strong>INDEMNITY:&nbsp; </strong>YOU AGREE TO RELEASE,
                    DEFEND, INDEMNIFY, AND HOLD WORKHUB GROUP HARMLESS FROM AND
                    AGAINST ANY AND ALL CLAIMS AND REMEDIES BROUGHT BY A THIRD
                    PARTY ARISING FROM OR RELATED TO ANY USE OF THE WEBSITE, THE
                    CONTENT, THE SOFTWARE, THE SERVICES, NOTWITHSTANDING THIS
                    INDEMNITY, WORKHUB GROUP RETAINS THE RIGHT TO PARTICIPATE IN
                    THE DEFENSE AND SETTLEMENT NEGOTIATIONS RELATING TO THE
                    FOREGOING WITH COUNSEL OF WORKHUB &rsquo;S SELECTION AT
                    WORKHUB GROUP&rsquo;S DISCRETION, AND SOLE COST AND EXPENSE.
                  </p>
                  <p>
                    <strong>
                      DISCLAIMER ON EXPERIMENTAL FEATURES INCLUDING BETA
                      FEATURES:&nbsp;{" "}
                    </strong>
                    FROM TIME TO TIME, NEW FEATURES THAT MAY BE ACCESSED FROM
                    OUR SOFTWARE OR THE WEBSITE FOR TESTING AND EXPERIMENTATION
                    BY YOU MAY BE PROVIDED, INCLUDING BETA FEATURES. SUCH NEW
                    FEATURES ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND
                    WITHOUT ANY REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF
                    ANY KIND, WHETHER EXPRESS, IMPLIED OR COLLATERAL, AND MAY BE
                    MODIFIED OR DISCONTINUED AT WORKHUB &rsquo;S DISCRETION. THE
                    PROVISIONS OF THIS AGREEMENT, INCLUDING ANY LIABILITY
                    DISCLAIMER, APPLY TO THE MAXIMUM EXTENT PERMITTED BY
                    APPLICABLE LAW TO SUCH FEATURES.
                  </p>
                  <p>
                    <strong>LIMITATION ON QUANTUM:&nbsp; </strong>
                    <u>
                      THE FOLLOWING CLAUSE IS NOT APPLICABLE TO CONSUMERS IN
                      QU&Eacute;BEC
                    </u>
                    :&nbsp; NOTWITHSTANDING THE FOREGOING, IF WE ARE FOUND TO BE
                    LIABLE, THE QUANTUM OUR LIABILITY TO YOU OR TO ANY OTHER
                    PERSON IS LIMITED TO THE TOTAL FEES PAID TO US BY YOU IN THE
                    12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY,
                    TO A MAXIMUM OF $200.
                  </p>
                  <p>
                    <strong>RESERVATION:&nbsp; </strong>SOME JURISDICTIONS DO
                    NOT ALLOW THE DISCLAIMER OF WARRANTIES OR CONDITIONS, OR
                    EXCLUSION OF DAMAGES, SO SUCH DISCLAIMERS AND EXCLUSIONS MAY
                    NOT APPLY TO YOU IF YOU MEET THE REQUIREMENTS TO BENEFIT
                    FROM THE LAWS OF SUCH JURISDICTION NOTWITHSTANDING THESE
                    TERMS AND CONDITIONS.
                  </p>
                  <p>
                    <strong>Notices:&nbsp; </strong>Except as expressly stated
                    otherwise, providing an email address or other information
                    to WORKHUB, constitutes your consent to receive any legal
                    notices required by applicable law at the an email address
                    or other contact information you provide to WORKHUB. Any
                    Legal Notice sent by email or other electronic communication
                    in this manner shall be deemed to have been received 24
                    hours after the email or other electronic communication is
                    sent by WORKHUB, unless WORKHUB is notified that the email
                    address is invalid. Alternatively, we may give you legal
                    notice by mail at any physical address you have provided to
                    WORKHUB. In such case, notice shall be deemed given three
                    days after the notice is sent in the mail by WORKHUB.
                  </p>
                  <p>
                    <strong>Legal Disputes:&nbsp; </strong>
                    <u>
                      The following clause is not applicable to consumers in
                      Qu&eacute;bec
                    </u>
                    :&nbsp; To the maximum extent permitted by applicable law,
                    unless WORKHUB&nbsp; agrees otherwise, any claim, dispute or
                    controversy, whether based on a legal theory including,
                    contract, tort, statute or regulation, and whether including
                    a claim for pre-existing, present or future remedies,
                    arising out of or relating the Website, the services, these
                    TERMS OF USE or the <Link to="/privacy" style={{margin: '0 5px'}}>Privacy Policy</Link>, will be determined by
                    final and binding arbitration to the exclusion of the
                    courts. Arbitration will be conducted in the city of Calgary
                    on a simplified and expedited basis by one arbitrator
                    pursuant to the <em>Arbitration Act</em> (Alberta). The
                    foregoing does not, however, preclude WORKHUB from seeking
                    injunctive relief when necessary, as determined by WORKHUB
                    in its discretion, to protect its interests. You agree that
                    any dispute resolution proceedings will be conducted only on
                    an individual basis and not in a class, consolidated or
                    representative action. If for any reason a Claim proceeds in
                    court rather than in arbitration, you waive any right to a
                    jury trial.
                  </p>
                  <p>
                    <strong>Law and Forum for Legal Disputes:</strong>&nbsp;{" "}
                    <u>For individual customers in Qu&eacute;bec</u>:&nbsp;
                    These TERMS OF USE will be interpreted in accordance with
                    the laws of the Qu&eacute;bec and the federal laws of Canada
                    applicable therein, without regard to conflict-of-law
                    provisions. You agree that to the extent, any claim or
                    dispute you may have against WORKHUB is resolved in Court,
                    the claim or dispute must be resolved exclusively by a
                    Provincial Court or Federal Court located in Qu&eacute;bec.
                    You agree to submit to the personal jurisdiction of the
                    courts located within Qu&eacute;bec for the purpose of
                    litigating all such claims or disputes.{" "}
                    <u>For customers outside of Qu&eacute;bec</u>:&nbsp; These
                    TERMS OF USE shall be governed in all respects by the laws
                    of Alberta and the applicable federal laws of Canada,
                    without regard to conflict of law provisions. You agree that
                    to the extent any claim or dispute you may have against
                    WORKHUB is resolved in Court, the claim or dispute must be
                    resolved exclusively by a Provincial Court or Federal Court
                    located in Alberta. You agree to submit to the personal
                    jurisdiction of the courts located within Alberta for the
                    purpose of litigating all such claims or disputes.
                  </p>
                  <p>
                  <strong>Charges and Payment of Fees:</strong>&nbsp;Payment terms are NET 30 days. When applicable you shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Payments must be made monthly at the end of each month unless otherwise mutually agreed upon by Workhub (Physical items ordered online through our catalogue are separately billed and the terms are due Net 15 and are subject to approved credit). All payment obligations are non-cancelable and all amounts paid are non-refundable. Workhub reserves the right to modify its fees and charges and to introduce new charges at any time, upon at least 30 days prior notice to you, which notice may be provided by e-mail or posted on the website. All pricing terms are confidential, and you agree not to disclose them to any third party.
                  </p>
                  <p><strong>Billing and Renewal:</strong>&nbsp;When applicable, Workhub will automatically issue an invoice to you each month or as otherwise mutually agreed upon. Fees for other services will be charged on an as-quoted basis. You agree to provide Workhub with complete and accurate billing and contact information. This information includes your legal company name, street address, e-mail address, and name and telephone number of an authorized billing contact. You agree to update this information within 30 days of any change to it. If the contact information you have provided is false or fraudulent, Workhub reserves the right to terminate your access to the Service in addition to any other legal remedies. Unless Workhub in its discretion determines otherwise: (i) entities with headquarters and a majority of users resident in the Canada will be billed in Canadian dollars and subject to Canadian payment terms and pricing schemes; (ii) entities with headquarters and a majority of users resident in the United States will be billed in U.S. dollars and subject to U.S. payment terms and pricing schemes; and (iii) all other entities will be billed in Canadian dollars, unless otherwise agreed, and be subject to Canadian payment terms and pricing schemes at the discretion of Workhub. If you believe your bill is incorrect, you must contact us within 10 days of the invoice date of the invoice containing the amount in question to be eligible to receive an adjustment or credit.</p>
                  <p>
                    <p><strong>Non-Payment and Suspension:</strong>&nbsp;In addition to any other rights granted to Workhub herein, Workhub reserves the right to suspend or terminate this Agreement and your access to the Service if your account becomes delinquent (falls into arrears). Delinquent invoices (accounts in arrears) are subject to interest of 2.0% per month on any outstanding balance, or the maximum permitted by law, whichever is less, plus all expenses of collection. If you or Workhub initiates termination of this Agreement, you will be obligated to pay the balance due on your account computed in accordance with the Charges and Payment of Fees section posted on our website. You agree that Workhub may charge such unpaid fees to your credit card or otherwise bill you for such unpaid fees. Workhub reserves the right to impose a reconnection fee in the event you are suspended and thereafter request access to the Service. You agree and acknowledge that Workhub has no obligation to retain Customer Data and that such Customer Data may be irretrievably deleted if your account is 30 days or more delinquent. </p>
                    <strong>Additional Terms and Conditions:&nbsp; </strong>
                    There are additional TERMS OF USE that apply to transactions
                    made through the Website, including use of the WORKHUB
                    Software, which will be posted and available for review and
                    acceptance at the time of the transaction. When making a
                    purchase, using a service, reviewing content or otherwise
                    accessing the Website, you are subject to any posted
                    policies or rules applicable both to any service you use
                    through the Website, and to the Website more broadly.
                    Subject to any such additional policies or rules, these
                    TERMS OF USE constitute the entire agreement between you and
                    WORKHUB with respect to the Website, the content, the
                    services and any information obtained through the Website by
                    communication with WORKHUB personnel.
                  </p>
                  <p>
                    <strong>Term and Termination:&nbsp; </strong>These TERMS OF
                    USE will become effective upon your acceptance of these
                    TERMS OF USE as updated from time to time by your use of the
                    Website or the services. These TERMS OF USE will remain in
                    full force and effect unless and until updated or terminated
                    hereunder. You acknowledge that WORKHUB&nbsp; has the right,
                    in its discretion, to terminate or suspend your access to
                    the Website, to terminate or suspend any Account, to limit
                    or deny your access to or participation in any content,
                    services offered by WORKHUB&nbsp; at any time without notice
                    to you and without liability to you or any person, if you
                    violate or threaten to violate any of these Terms and
                    Conditions, if you violate or threaten to violate any rights
                    of WORKHUB , interfere with any other person&rsquo;s access
                    to or use of the Website, or if WORKHUB&nbsp; decides in its
                    discretion for any reason whatsoever that any Account,
                    access to the Website by any person, access to content by
                    any person, including use of any service or purchase of any
                    product, is otherwise detrimental to the Website,
                    WORKHUB&nbsp; or WORKHUB&nbsp; Group more broadly, or
                    WORKHUB suppliers or licensors. If you believe that, someone
                    has violated these Terms and Conditions, please contact
                    WORKHUB with details. WORKHUB may decide, in its discretion,
                    to investigate the report and decide, in its discretion, to
                    take any action relating to that report. WORKHUB does not
                    have any obligation or liability to you for the performance
                    or non-performance of those activities and has no obligation
                    to communicate any decision or action taken because of your
                    communication with WORKHUB.
                  </p>
                  <p>
                    <strong>Interpretation:&nbsp; </strong>In these Terms and
                    Conditions, (<strong>a</strong>) headings are for reference
                    purposes only and do not limit the scope or extent of such
                    section; (<strong>b</strong>) words importing the singular
                    number only also include the plural, and vice versa; (
                    <strong>c</strong>) &ldquo;<strong>person</strong>&rdquo;
                    includes an individual, corporation and any other legal
                    entity; (<strong>d</strong>) &ldquo;
                    <strong>including</strong>&rdquo; or &ldquo;
                    <strong>includes</strong>&rdquo; means including or includes
                    (as applicable) without limitation or restriction; (
                    <strong>e</strong>) &ldquo;<strong>law</strong>&rdquo;
                    includes common law, equity, statutes and regulations; and (
                    <strong>f</strong>) &ldquo;<strong>discretion</strong>
                    &rdquo; mean a person&rsquo;s sole, absolute and unfettered
                    discretion.
                  </p>
                  <p>
                    <strong>General:&nbsp; </strong>You can communicate with
                    WORKHUB by email as indicated in these Terms and Conditions.
                    You can communicate with WORKHUB by mail at{" "}
                    <strong>info@Workhub.com</strong>. If any provision of these
                    TERMS OF USE is held to be invalid or unenforceable, such
                    provision shall be struck and the remaining provisions shall
                    be enforced. Our failure to act with respect to a breach by
                    you or others does not waive our right to act with respect
                    to subsequent or similar breaches. We do not guarantee we
                    will take action against all breaches of these Terms and
                    Conditions.
                  </p>
                  <p>
                    <strong>Language:&nbsp; </strong>You and WORKHUB have each
                    expressly requested and required that these TERMS OF USE and
                    all related notices and other documents be drawn up in the
                    English language.{" "}
                    <em>
                      Les parties conviennent et exigent express&eacute;ment que
                      ce Contrat et tous les documents qui s'y rapportent soient
                      r&eacute;dig&eacute;s en anglais
                    </em>
                    . Subject to applicable law, any non-English translation of
                    these TERMS OF USE provided by WORKHUB is for convenience
                    only, and if there is a conflict or inconsistency between
                    the English version and a non-English version then the
                    English version of these TERMS OF USE will take priority and
                    govern.
                  </p>
                  <p>
                    If you have any questions, comments or concerns about these
                    Conditions, please contact WORKHUB&rsquo;s customer service
                    department by email at <strong>info@Workhub.com </strong>
                    with attention to the{" "}
                    <strong>Customer Service Department.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Terms
